import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://backoffice-ecgz.onrender.com';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Upflow',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/a3f50a2d-ef95-42df-e6c0-f4e45357d100/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#0C244A',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#fff',
};
